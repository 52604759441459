<template>
  <div
    class="about-us"
    oncontextmenu="return false"
    onselectstart="return false"
  >
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div id="pdfDom" class="content">
      <template v-if="title_id == 4">
        <div class="table" :class="{ pdfheight: isPrint }">
          <h1 class="title">
            <b>
              【儿童写字表现评量表（学龄版）】<br />
              <p style="margin-top: 5px">
                <i>Chinese Handwriting Evaluation Form (CHEF)</i>
              </p>
              <br />
              个人报告
            </b>
          </h1>
          <div class="author">
            <p>张韶霞、余南莹 编制</p>
          </div>
          <p class="title3 tx-l">量表介绍</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th>
                <p class="tips tx-idt2">
                  本量表学龄版的评估目的是筛选出写字困难的儿童，依其表现的写字特征加以分类，并根据学童的障碍来厘清学童本身能力缺陷与写字困难型态的关联性，才能依据既有的教学或动作学习理论提出完整的介入计划。学龄版量表包含「工整性」、「正确性」、「速度」、「握笔工学」、「方向性」五个向度，以此五个向度来观察学童写字困难问题。进一步可从向度的分布型态区分出五种亚型，五种亚型各有不同写字问题的特质，本量表可进一步针对不同亚型安排介入计划。
                </p>
              </th>
            </tr>
          </table>
          <div class="head-title">
            <p class="title3 tx-l">基本资料</p>
            <p class="title3 tx-l">个案编号：{{ baseInfo.sn }}</p>
          </div>

          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th>儿童姓名</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.child_name" />
                </div>
              </td>
              <th>儿童性别</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.sex" />
                </div>
              </td>
            </tr>

            <tr>
              <th>惯用手</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.hand" />
                </div>
              </td>

              <th>出生日期</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.birthday" />
                </div>
              </td>
            </tr>

            <tr>
              <th>实足年龄</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.age" />
                </div>
              </td>
              <th>换算月龄</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.month_age" />
                </div>
              </td>
            </tr>
            <tr>
              <th>评量日期</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.create_time" />
                </div>
              </td>
              <th>居住地区</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    :value="
                      baseInfo.province + baseInfo.city + baseInfo.district
                    "
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>测评老师</th>

              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.operator"
                  />
                </div>
              </td>
              <th></th>
              <td>
                <div class="input"></div>
              </td>
            </tr>
          </table>
        </div>
        <div class="table" :class="{ pdfheight: isPrint }">
          <!-- 分数结果 -->
          <p class="title3 tx-l">分数结果</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th width="160">向度</th>
              <th>原始分数</th>
              <th>平均值</th>
              <th>百分位数</th>
              <th>结果</th>
            </tr>
            <tr v-for="(item, index) in cateScore" :key="index">
              <td class="tx-c gray">{{ item.cate_name }}</td>
              <td class="tx-c">{{ item.score }}</td>
              <td class="tx-c">{{ item.average }}</td>
              <td class="tx-c">{{ item.percent }}</td>
              <td class="tx-c">{{ item.conclusion }}</td>
            </tr>
          </table>

          <!-- 分数说明 -->
          <p class="title3 tx-l">分数说明</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th width="120">类别</th>
              <th>说明</th>
            </tr>
            <tr>
              <td class="tx-c">平均值</td>
              <td>
                <p class="tips">
                  在本量表原始分数愈高代表写字能力愈差，各题填答3分者〔即填答有些符合（出现频率40%～60%）〕，代表儿童已符合该问题的情况。以常模平均数来看，则是以3.185为筛检儿童写字困难的切截分数。
                </p>
              </td>
            </tr>
            <tr>
              <td class="tx-c">百分位数</td>
              <td>
                <p class="tips">
                  百分位数是依据常模资料换算而得，代表儿童在100个人当中所排列的位置。在本量表百分位数愈高，代表写字表现愈佳。在本量表第26百分位数以上归类为正常范围；第16与第25百分位数之间归类为边缘，为需注意的范围；第6与第15百分位数之间归类为轻中度障碍，代表已明确符合本量表所陈列的问题；第5百分位数以下代表已呈现出严重问题，归类为明显障碍。
                </p>
              </td>
            </tr>
          </table>
          <p class="title3 tx-l">◆ 写字困难问题</p>
          <p class="first-line">{{ result.res }}</p>
          <p class="line-note">说明：{{ result.explain }}</p>
          <p class="title3 tx-l" style="margin-top: 10px">◆ 提笔姿势</p>
          <div style="padding-left: 20px">
            <div v-html="holding"></div>
          </div>
        </div>
        <!--        :class="{'pdfheight': isPrint}"-->
        <div class="table">
          <!-- ◆	解释与建议 -->
          <p class="title3 tx-l">◆ 介入计划与建议</p>
          <div class="tips">
            <p v-if="result.res == '不好程度'">
              儿童在本量表的表现位于“不好程度”，除了需培养写字先备能力之外还需加强动作训练，建议如下：
            </p>
            <p class="tx-idt2" style="text-indent: 0" v-html="result.desc"></p>
          </div>
          <!-- <p class="title3 tx-l">◆ 备注</p>
          <p class="first-line">如果您对本测验结果或解释有任何疑问，或是想进一步了解的地方，请与施测人员共同澄清讨论。</p>
          <br>
          -->
          <p class="title3 tx-l">◆ 补充说明</p>
          <p class="first-line">{{ baseInfo.remark }}</p>
        </div>
      </template>
      <!-- 学前版 -->
      <template v-if="title_id == 3">
        <div class="table" :class="{ pdfheight: isPrint }">
          <h1 class="title">
            <b>
              【儿童写字表现评量表（学前版）】<br />
              <p style="margin-top: 5px">
                <i>Chinese Handwriting Evaluation Form (CHEF)</i>
              </p>
              <br />个人报告
            </b>
          </h1>
          <div class="author">
            <p>张韶霞、余南莹 编制</p>
          </div>
          <p class="title3 tx-l">量表介绍</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th>
                <p class="tips tx-idt2">
                  本量表学前版主要是希望对幼儿园儿童提供一个写字先备能力的程度分析，及早了解儿童是否已经达到学习写字的准备。学前版量表包含「工整性」、「功能性」、「握笔工学」、「写字行为」四个向度，从各向度结果可以呈现已经具备或仍不足的能力，了解儿童写字能力的发展阶段或练习成效。
                </p>
              </th>
            </tr>
          </table>
          <div class="head-title">
            <p class="title3 tx-l">基本资料</p>
            <p class="title3 tx-l">个案编号：{{ baseInfo.sn }}</p>
          </div>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th width="180px">儿童姓名</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.child_name" />
                </div>
              </td>
              <th width="180px">评量日期</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.create_time" />
                </div>
              </td>
            </tr>
            <tr>
              <th>儿童性别</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.sex" />
                </div>
              </td>
              <th>出生日期</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.birthday" />
                </div>
              </td>
            </tr>
            <tr>
              <th>惯用手</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.hand" />
                </div>
              </td>
              <th>实足年龄</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.age" />
                </div>
              </td>
            </tr>
            <tr>
              <th>障碍问题</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.disable"
                  />
                </div>
              </td>
              <th>填答人员</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.operator"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>是否上幼儿园</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.is_school"
                  />
                </div>
              </td>
              <th>与儿童关系</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.relation"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th v-if="baseInfo.detail.is_school == '是'">幼儿园名称</th>
              <th v-else>电子邮件</th>
              <td v-if="baseInfo.detail.is_school == '是'">
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.school_name"
                  />
                </div>
              </td>
              <td v-else>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.email" />
                </div>
              </td>
              <th>联络电话</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mobile"
                  />
                </div>
              </td>
            </tr>
            <tr v-if="baseInfo.detail.is_school == '是'">
              <th>目前就读</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.school_start"
                  />
                </div>
              </td>
              <th>电子邮件</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.email" />
                </div>
              </td>
            </tr>
            <tr v-if="baseInfo.detail.is_school == '是'">
              <th>开始上幼儿园时间</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.school_start"
                  />
                </div>
              </td>
              <th>幼儿园每日写绘占</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.write_percent"
                  />
                </div>
              </td>
            </tr>
            <tr v-if="baseInfo.detail.is_school == '是'">
              <th>幼儿园写字教学</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.teach_write"
                  />
                </div>
              </td>
              <th>回家练习写字功课</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.home_write"
                  />
                </div>
              </td>
            </tr>
            <tr v-if="baseInfo.detail.is_school == '是'">
              <th>开始写字教学时间</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.write_start"
                  />
                </div>
              </td>
              <th>回家练习写字频率</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.write_rate"
                  />
                </div>
              </td>
            </tr>
          </table>
          <!--  -->
          <p class="title3 tx-l">家庭资料</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th width="140px">主要照顾者</th>
              <td colspan="3">
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.care_man"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>父亲年龄</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.father_age"
                  />
                </div>
              </td>
              <th>母亲年龄</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mother_age"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>父亲教育程度</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.father_edu"
                  />
                </div>
              </td>
              <th>母亲教育程度</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mother_edu"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>父亲职业类别</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.father_job"
                  />
                </div>
              </td>
              <th>母亲职业类别</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mother_job"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="table" :class="{ pdfheight: isPrint }">
          <!-- 分数结果 -->
          <p class="title3 tx-l">分数结果</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th width="160">向度</th>
              <th>原始分数</th>
              <th>平均值</th>
              <th>百分位数</th>
              <th>结果</th>
            </tr>
            <tr v-for="(item, index) in cateScore" :key="index">
              <td class="tx-c gray">{{ item.cate_name }}</td>
              <td class="tx-c">{{ item.score }}</td>
              <td class="tx-c">{{ item.average }}</td>
              <td class="tx-c">{{ item.percent }}</td>
              <td class="tx-c">{{ item.conclusion }}</td>
            </tr>
          </table>
          <!-- 分数说明 -->
          <p class="title3 tx-l">分数说明</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th width="120">类别</th>
              <th>说明</th>
            </tr>
            <tr>
              <td class="tx-c">平均值</td>
              <td>
                <p class="tips">
                  在本量表原始分数愈高代表写字能力愈差，各题填答3分者〔即填答有些符合（出现频率40%～60%）〕，代表儿童已符合该问题的情况。以常模平均数来看，则是以3.185为筛检儿童写字困难的切截分数
                </p>
              </td>
            </tr>
            <tr>
              <td class="tx-c">百分位数</td>
              <td>
                <p class="tips">
                  百分位数是依据常模资料换算而得，代表儿童在100个人当中所排列的位置。在本量表百分位数愈高，代表写字先备能力愈佳。本量表第26百分位数以上归类为正常范围；第16与第25百分位数之间归类为边缘，为需注意的范围；第6与第15百分位数之间归类为轻中度不足，代表已明确符合本量表所陈列的问题；第5百分位数以下代表写字先备能力归类为明显不足。
                </p>
              </td>
            </tr>
          </table>
          <p class="title3 tx-l">◆ 写字先备能力程度</p>
          <p class="first-line">{{ result.res }}</p>
          <p class="line-note">说明：{{ result.explain }}</p>
          <p class="title3 tx-l" style="margin-top: 10px">◆ 提笔姿势</p>
          <div style="padding-left: 20px">
            <div v-html="holding"></div>
          </div>
        </div>
        <!--        :class="{'pdfheight': isPrint}"-->
        <div class="table">
          <!-- ◆	解释与建议 -->
          <p class="title3 tx-l">◆ 介入计划与建议</p>
          <div class="tips">
            <p v-if="result.res == '不好程度'">
              儿童在本量表的表现位于“不好程度”，除了需培养写字先备能力之外还需加强动作训练，建议如下：
            </p>
            <div style="height: 20px"></div>
            <p v-html="result.desc"></p>
          </div>
          <p class="title3 tx-l">◆ 备注</p>
          <p class="first-line">
            如果您对本测验结果或解释有任何疑问，或是想进一步了解的地方，请与施测人员共同澄清讨论。
          </p>
          <br />
          <p class="title3 tx-l">◆ 补充说明</p>
          <p class="first-line">{{ baseInfo.remark }}</p>
        </div>
      </template>
    </div>
    <!--底部-->
    <button class="print" v-on:click="printPdf">打印</button>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
import echarts from "echarts";

export default {
  name: "aboutus",
  data() {
    return {
      current: "",
      baseInfo: {},
      cateScore: [],
      holding: "",
      result: "",
      title_id: this.$route.query.id,
      isPrint: false,
    };
  },
  components: {
    HeaderNav,
    FooterNav,
    echarts,
  },
  methods: {
    getData() {
      let params = {
        exam_id: this.$route.query.eid,
      };
      this.$post("report/getReport", params)
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.baseInfo = res.data.base_info;
            this.cateScore = res.data.cate_score;
            this.holding = res.data.holding;
            this.result = res.data.result;
            this.$nextTick(() => {
              if (this.$route.query.print) {
                this.printPdf();
              }
            });
          } else {
            this.$layer.msg(res.msg);
          }
        })
        .catch((response) => {
          this.$layer.msg("LOADING...");
        });
    },
    printPdf() {
      this.isPrint = true;
      setTimeout(() => {
        this.getPdf("儿童写字表现评量表", () => {
          this.isPrint = false;
        });
      }, 100);
    },
  },
  mounted() {},
  created() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/page/result");
</style>
